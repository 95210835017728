:root{
    --navy:#112240;
    --tranYellow:#ffdd0063;
    --lessGray:#a8b2d1
}


@media only screen and (max-width: 650px){/*Smaller than 750px*/
    .projects{
        margin-top:50px;
        width:100%!important;
    }
        .projects li{
            width:87vw!important;
            height:300px!important;
        }
        .description{
            height:180px!important;
        }
        .load{
            margin-bottom:80px;
        }
}


@keyframes loadIn {
    from{opacity:0;}
    to{opacity:1;}
}


.projects{
    margin-left:50%;
    transform:translate(-50%,0);
    width:90%;
    max-width:1032px;


}
    .workHeader h3{
        font-size:40px;
        display:inline-block;
        color:var(--yellow);
        margin-left:10px;
        margin:30px 0px 30px 10px;
    }
        .workHeader::after{
            content:"";
            width:calc(100% - (690px));
            height:1px;
            background-color:var(--yellow);
            display:inline-block;

            margin-bottom:8px;
            margin-left:20px;
           

        }
    .projects ul{
        list-style:none;
    }
        .projects li{
            display:inline-block;
            border:2px solid var(--navy);
            margin: 10px;
            width:300px;
            height:330px;

            background-color:var(--navy);
            padding:10px;


            opacity:0;
            animation: loadIn 1s;
            animation-fill-mode:forwards !important;

            transition:transform .3s;
        }
            .projects li:hover{
                transform:translate(0px, -5px);               
            }
            .insideContainer{
                display:grid;
            }
                .logo{
                    fill:var(--yellow);
                    height:40px;
                }
                    .logo a{
                        cursor:pointer;

                    }
                    .ext{
                        float:right;
                        width:20px;
                        height:auto;
                        fill:var(--lightGray);
                        cursor:pointer;
                    }
                    .folder{
                        float:left;
                        width:40px;
                        height:auto;
                    }
                .title{

                    color:var(--fontColor);
                    display:block;
                    font-size:20px;
                    padding:10px 0px;
                    font-weight: 900;
                    height:35px;
                }
                .description{
                    color:var(--lessGray);
                    font-size:15px;
                    height:211px;
                }
                footer{
                    margin-top:5px;
                    color:var(--lightGray);
                    font-size: 12px;
                }
    .load{
        color:var(--yellow);
        margin-left:50%;
        transform: translate(-50%,0);
        border:2px solid var(--yellow);
        padding:20px;
        border-radius:20px;
        cursor:pointer;

        transition:background-color .3s;
    }
        .load:hover{
            background-color:var(--tranYellow);
        }