:root{
    --crymson: #da015b;
    --lightGray: #ffffff70;
    --borderColor:#da015b5b;
    --backgroundColor:#172a45;
    --fontColor:#e6f1ff;
    --yellow:#ffdf00;
    --darkBlue:#01014a;


    --width:650px;
}



@media only screen and (max-width: 650px){/*Smaller than 650px*/
    .links{
        display:none;
    }
    .email{
        display:none;
    }
    #tabs{
        width:93vw;
        position:fixed;
        margin-top:0px;
    }
    #footer{
        display:none;
    }
    #navBar{
        display:none;
    }
}
@media only screen and (min-width: 650px) {/*Larger than 650px*/
    #tabs{
        width:calc(100vw - 200px);
        margin-top:57px;
    }
    
}
@media only screen and (max-height: 170px) {/*Smaller than 170px*/
    #footer{
        display:none;
    }
    
}





#app{
    height:100vh;
    color:var(--fontColor);
    width:100vw;
 
    background-color:var(--backgroundColor);
    margin:0px;
    padding:0px;

    overflow-y: hidden;
}


a{
    text-decoration: none !important;
    cursor:pointer;
}


#navBar{
   list-style:none;
    counter-reset:header;
    float:right;
    z-index:200;
}
    #navBar li{
        user-select: none;

        z-index:100;
        cursor:pointer;
        display:inline-block;
        color:#e6f1ff;
        counter-increment: item 1;
        margin: 10px 15px 10px 15px;
        padding: 10px;
        border-bottom:2px solid transparent;
        font-size:14px;
        transition:color .2s;
    }
        #navBar li:hover{
            color:#ffdf00;;
        }
        #navBar li::before{
            
            counter-increment:header;
            content:"[" counter(header) "] ";
            color:#ffdf00;;
        }


.links{
    position:absolute;
    float:left;
    bottom:0px;
    z-index:0
    
}
    /*.links::before{
        content: "";
        display: block;
        width: 1px;
        height: 20vh;
        margin: 0px auto;
        background-color: var(--fontColor);
        padding-bottom:600px;
    }*/
    .links::after{
        content: "";
        display: block;
        width: 1px;
        height: 200px;
        margin: 0px auto;
        background-color: var(--fontColor);
        margin-top:7px;
    }
    .svg{
        cursor:pointer;
        fill:var(--fontColor);
        width:30px;
        margin:7px 20px;
        padding-top:3.5px;
        padding-bottom:3.5px;

        text-overflow: none;

        transition:padding .3s, fill .3s;
    }
        .svg:hover{
            cursor:pointer;
            padding-bottom:7px !important;
            padding-top:0px !important;
            fill:var(--yellow) !important;
        }
  

.emailLine{
    position: fixed;
    
    left: auto;
    right: 20px;
    bottom:0px;
    
}
   /* .emailLine::before{
        content: "";
        display: block;
        width: 1px;
        height: 60vh;
        margin: 0px auto;
        background-color: var(--fontColor);
        margin-bottom:30px;
    }*/
    .emailLine::after{

        content: "";
        display: block;
    
        width: 0.1px;
        height: 200px;
        margin: 0px auto;
        background-color: var(--fontColor);
    }
    .email,.email span{
        cursor:pointer;
        
        width: 40px;
        position:block;
        
        margin-bottom:280px;
        color: var(--light-slate);
        transform:rotate(90deg);

        transition:margin .3s, color .3s;
    }
    .email span{

        text-decoration:none;
    }
        .email:hover,.email span:hover{
            margin-top:-7px;
            margin-bottom:287px;
            color:var(--yellow);
        }


#tabs{
    position:fixed;
    list-style:none;
    background-color:var(--borderColor);

    border:2px solid var(--borderColor);
    border-bottom:.00000001px solid var(--darkBlue);
    padding:3px 20px 0px 20px;

    left:50%;
    transform:translate(-50%,0);

    z-index:2;

    border-radius:10px 10px 0px 0px;

    cursor:grab;
    }
    #tabs li {
        
        user-select:none;

        display:inline-block;
        margin:0px 5px;
        font-size:19px;
    }
    .terminal{
        z-index:-2000!important;

        cursor:pointer;
    
        padding:5px 15px;
        border-left:2px solid var(--crymson);
        border-right:2px solid var(--crymson);
        border-bottom:2px solid var(--crymson);
        border-top:2px solid var(--crymson);
        background-color:var(--crymson);
        border-radius:5px 5px 0px 0px;
        opacity:.8;
    
        transition: opacity .2s;
    }
        .terminal:hover{
            opacity: 1;
        }
    .indTab{
        z-index:-2000!important;

        cursor:pointer;
    
        padding:5px 15px;
        border-left:2px solid var(--crymson);
        border-right:2px solid var(--crymson);
        border-bottom:2px solid var(--crymson);
        border-top:2px solid var(--crymson);
        background-color:var(--crymson);
        border-radius:5px 5px 0px 0px;
        opacity:.8;
    
        transition: opacity .2s;

        transform:translate(-100%,0);
        animation: newTab 1s;
        animation-fill-mode:forwards !important;
    }
    @keyframes newTab{
        from{
            transform:translate(-100%,0);

        }
        to{
            transform:translate(0%,0);
            z-index: 0;
        }

    }
        .indTab:hover{
            opacity:1;
        }
        .selectedTab{
            cursor:default;
            opacity:1;
            border-bottom:2px solid var(--darkBlue);
            border-top:2px solid var(--darkBlue);
            border-color:var(--darkBlue);
            background-color:var(--darkBlue);
            
            border-radius:5px 5px 0px 0px;
        }
    .closeX{
        cursor:pointer;
        border:1px solid transparent;
        margin-left:10px;
        margin-right:-10px;
        padding:2px 5px 2px 6px;
        border-radius:50%;
        font-weight: lighter;

        z-index:2000;

        transition: border-color .2s, background-color .2s;
    }
        .closeX:hover{
            border-color:var(--fontColor);
            background-color:trasnparent;
        }
    #addTab{
        cursor:pointer;

        display:inline-block;
        border:1px solid transparent;
        border-color:transparent;
        font-size:2px;
        color:var(--fontColor);
        padding:0px 4px 1px 5px;
        border-radius:50%;

        transition: border-color .2s, background-color .2s;
    }
        #addTab:hover{
            border-color: var(--fontColor);
            background-color:transparent;
        }


#footer{
    width:80vw;
    font-size:13px;
    color:var(--fontColor);
    text-align: center;
    position:absolute;
    bottom:15px;
    transition:color .2s;

    left:50%;
    transform:translate(-50%,0%);
}
    #footer:hover{
        color:var(--yellow);
        cursor:pointer;
    }